import { IGetFieldNamesProps } from '@pages/ApplicationDetails/types';

export const getFieldNames = ({
  isCompass = false,
  translate,
}: IGetFieldNamesProps) => {
  const overview = {
    applicationReference: translate('OPS_PORTAL.TITLE.APPLICATION_REFERENCE'),
    paymentPlanId: translate('TITLE.PAYMENT_PLAN_ID'),
    applicationUuid: translate('OPS_PORTAL.TITLE.APPLICATION_UUID'),
    contractUUID: translate('OPS_PORTAL.LABEL.CONTRACT_UUID'),
    merchantName: translate('LABEL.MERCHANT_NAME'),
    merchantUserId: translate('OPS_PORTAL.LABEL.MERCHANT_USER_ID'),
    merchantUserName: translate('OPS_PORTAL.LABEL.MERCHANT_USER_NAME'),
    merchantUserPhoneNumber: translate(
      'OPS_PORTAL.LABEL.MERCHANT_USER_PHONE_NUMBER',
    ),
    merchantOrderReference: translate(
      'OPS_PORTAL.LABEL.MERCHANT_ORDER_REFERENCE',
    ),
    productName: translate('OPS_PORTAL.LABEL.PRODUCT_NAME'),
    subProductName: translate('OPS_PORTAL.LABEL.SUB_PRODUCT_NAME'),
    storeId: translate('TITLE.STORE_ID'),
    ...(isCompass
      ? { praticaId: translate('OPS_PORTAL.TITLE.PRATICA_ID') }
      : {}),
    createdDate: translate('OPS_PORTAL.TITLE.CREATED_DATE'),
    applicationAmount: translate('OPS_PORTAL.LABEL.APPLICATION_AMOUNT'),
    numberOfInstallments: translate('LABEL.NUMBER_OF_INSTALMENTS'),
    monthlyPaymentAmount: `${translate(
      'OPS_PORTAL.LABEL.MONTHLY_PAYMENT_AMOUNT',
    )} (${translate('OPS_PORTAL.LABEL.INFERRED')})`,
    activityStatus: translate('OPS_PORTAL.LABEL.ACTIVITY_STATUS'),
    currentPhase: translate('OPS_PORTAL.LABEL.CURRENT_PHASE'),
    identityStatus: translate('OPS_PORTAL.LABEL.IDENTITY_STATUS'),
    creditStatus: translate('OPS_PORTAL.LABEL.CREDIT_STATUS'),
    paymentMethodStatus: translate('OPS_PORTAL.LABEL.PAYMENT_METHOD_STATUS'),
    paymentMethod: translate('OPS_PORTAL.LABEL.PAYMENT_METHOD'),
    creditType: translate('OPS_PORTAL.LABEL.CREDIT_TYPE'),
    riskConfigName: translate('OPS_PORTAL.LABEL.RISK_CONFIG_NAME'),
    waterfallConfigName: translate('OPS_PORTAL.LABEL.WATERFALL_CONFIG_NAME'),
  };

  const applicantDetails = {
    consumerUUID: translate('OPS_PORTAL.LABEL.CONSUMER_UUID'),
    applicantUUID: translate('OPS_PORTAL.LABEL.APPLICANT_UUID'),
    applicantName: translate('TITLE.CUSTOMER_NAME'),
    dateOfBirth: translate('OPS_PORTAL.LABEL.DATE_OF_BIRTH'),
    email: translate('LABEL.EMAIL_ADDRESS'),
    customerAddress: translate('LABEL.ADDRESS'),
    phoneNumber: translate('LABEL.PHONE_NUMBER'),
    language: translate('LABEL.LANGUAGE'),
    lexDigitalId: translate('OPS_PORTAL.LABEL.LEX_DIGITAL_ID'),
  };

  return {
    applicantDetails,
    overview,
  };
};
